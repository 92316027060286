import { Link } from 'react-scroll'
import { Link as Home} from 'react-router-dom'
import { useState, useEffect } from 'react'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// styles
import './m-header.css'
import './d-header.css'

const Header = () => {

	useEffect(() => {
		document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
	}, [])

	const [isOpen, setIsOpen] = useState(false)
	const isOpenState = () => {
        setIsOpen(!isOpen)
    }

	const [isActu, setIsActu] = useState(false)
	const isActuState = () => {
        setIsActu(!isActu)
    }

	return (
		<>
			<nav id='navbar' className={	
				isOpen ? 
				'navbar navbar-open':
				'navbar navbar-closed'}>

				<Home to='/'>
					<img 
						className='navbar__logo-bcra' 
						src={isOpen ? `${process.env.PUBLIC_URL}/assets/pictures/logo-bcra-yel.png` : `${process.env.PUBLIC_URL}/assets/pictures/logo-bcra.png`}
						width='1788px'
						height='1092px'
						fetchpriority='high'
						alt='bcra-icon' 
					/>
				</Home>	
				
				<div
					className={
						isOpen ? 
						'nav__menu__link-header actu white' : 'nav__menu__link-header actu gold'}
					onClick={isActuState}
				>
					Nos actualités
				</div>
				<FontAwesomeIcon 
					onClick={isOpenState} 
					className={
						isOpen ?
						'navbar__button-bars closed':
						'navbar__button-bars open'} 
					icon={faBars} 
				/>
				<FontAwesomeIcon 
					onClick={isOpenState}
					className={
						isOpen ?
						'navbar__button-x open':
						'navbar__button-x closed'} 
					icon={faXmark} 
				/>
				<menu className={
					isOpen ?
					'navbar__menu navbar__menu-open':
					'navbar__menu navbar__menu-closed'}>
					<Link onClick={isOpenState} to='/'>
						<div
							className='nav__menu__link'>
							Accueil
						</div>
					</Link>
					<Link to='about'>
						<div
							className='nav__menu__link'>
							Qui nous sommes
						</div>
					</Link>
					<Link to='prestations'>
						<div 
							className='nav__menu__link'>
							Nos prestations
						</div>
					</Link>
					<Link to='realisations'>
						<div
							className='nav__menu__link'>
							Nos réalisations
						</div>
					</Link>
					<Link to='agences'>
						<div
							className='nav__menu__link'>
							Nos agences
						</div>
					</Link>
					<Link to='contact'>
						<div
							className='nav__menu__link'>
							Contact
						</div>
					</Link>
				</menu> 
			</nav>
			<div 
				id='actu'
				className={
				isActu ?
				'actu-visible':'actu-hidden'
				}
			>
				<div className='actu-container'>
					<div 
						onClick={isActuState}
						className='actu-container__closed' 
					>
						<FontAwesomeIcon icon={faXmark} />
					</div>
					<header>
						<h1>Actualités 2023</h1>
					</header>
					<h2>Bonjour à tous,</h2>
					<p>
					1- Nous sommes ravis d'annoncer notre partenariat avec 2B réalisation.
					<br/>
					Ensemble, nous unissons nos forces et notre expertise pour offrir des solutions exceptionnelles à nos clients communs.
					</p>
					<p>
					2- Nous sommes ravis de vous présenter notre nouveau site web réalisé par la société VOWD.fr.
					</p>
					<br/>
				</div>
			</div>
		</>
	)
}
 
export default Header